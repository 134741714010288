import { createSelector } from "reselect"

const classesSelector = state => {
  return state.classes
}
export const selectAllClasses = createSelector(
  [classesSelector],
  classes => classes
)

export const selectOnlineClasses = createSelector(
  [classesSelector],
  classes => {
    return classes.filter(item => {
      return item.VirtualClassUrl.length > 0
    })
  }
)

export const selectXbergClasses = createSelector([classesSelector], classes => {
  return classes.filter(item => item.Location === "Green Yoga Kreuzberg")
})
export const selectPbergClasses = createSelector([classesSelector], classes => {
  return classes.filter(item => item.Location === "Green Yoga Prenzlauerberg")
})
export const selectSEZClasses = createSelector([classesSelector], classes => {
  return classes.filter(item => item.Location === "Green Yoga SEZ")
})
export const selectPankowClasses = createSelector([classesSelector], classes =>{
  return classes.filter(item => item.Location === "Green Yoga Pankow")
})

export const selectOutdoorClasses = createSelector([classesSelector], classes =>
  classes.filter(
    item =>
      item.Location === "Green Yoga HASENHEIDE" ||
      item.Location === "Green Yoga Volkspark Fhain"
  )
)
export const selectVOLKSPARKClasses = createSelector(
  [classesSelector],
  classes => classes.filter(item => item.Location === "Green Yoga Volkspark Fhain")
)
export const selectHASENHEIDEClasses = createSelector(
  [classesSelector],
  classes => classes.filter(item => item.Location === "Green Yoga HASENHEIDE")
)

const locationsSelector = state => state.locations
export const selectLocations = createSelector(
  [locationsSelector],
  locations => locations
)
const scheduleLocationSelector = state => state.scheduleLocation
export const selectScheduleLocation = createSelector(
  [scheduleLocationSelector],
  scheduleLocation => scheduleLocation
)

export const selectClassesLocations = createSelector(
  [locationsSelector, scheduleLocationSelector],
  (locations, scheduleLocation) => {
    let locationsArray = []

    if (scheduleLocation === "Online") {
      locationsArray = [{ name: "Online Classes", value: "Online Classes" }]
      return locationsArray
    }
    if (scheduleLocation === "Outdoor") {
      locations.map(
        location =>
          (location.name.includes("VOLKSPARK FX") ||
            location.name.includes("HASENHEIDE")) &&
          locationsArray.push({
            value: location.name,
            name: location.name,
          })
      )
      return locationsArray
    }
    if (scheduleLocation === "InStudio") {
      locations.map(
        location =>
          location.name.includes("Green Yoga Prenzlauerberg") ||
          location.name.includes("Green Yoga Kreuzberg") ||
          location.name.includes("Green Yoga Pankow") ||
          (location.name.includes("Green Yoga SEZ") &&
            locationsArray.unshift({
              value: location.name,
              name: location.name,
            }))
      )

      return locationsArray
    }
  }
)
const trainersSelector = state => state.trainers
export const selectTrainers = createSelector(
  [trainersSelector],
  trainers => trainers
)
export const selectTrainersList = createSelector(
  [trainersSelector],
  trainers => {
    let teachers = [{ name: "All Teachers", value: "All Teachers" }]
    let t = trainers.map(trainer => ({
      name: trainer.firstName,
      value: trainer.firstName,
    }))
    t.sort((a, b) => (a.name > b.name ? 1 : -1))
    teachers = [...teachers, ...t]

    return teachers
  }
)

const yogaStylesSelector = state => state.yogaStyles
export const selectYogaStyles = createSelector(
  [yogaStylesSelector],
  yogaStyles => {
    return yogaStyles.map(item => ({
      name: item,
      value: item,
    }))
  }
)
export const selectSpecificClasses = createSelector(
  [selectAllClasses],
  classes => classes
)

export const selectLocationSelector = location => {
  return location === "Green Yoga Kreuzberg"
    ? selectXbergClasses
    : location === "Green Yoga Prenzlauerberg"
    ? selectPbergClasses
    : location === "Green Yoga SEZ"
    ? selectSEZClasses
    : location === "Green Yoga Pankow"
    ? selectPankowClasses
    : location === "Online Classes"
    ? selectOnlineClasses
    : location === "Green Yoga HASENHEIDE"
    ? selectHASENHEIDEClasses
    : selectVOLKSPARKClasses
}

const locationFilterSelector = state => state.locationFilter
const teachersFilterSelector = state => state.teacherFilter
const classFilterSelector = state => state.classFilter

export const selectCurrentFilter = createSelector(
  [locationFilterSelector, teachersFilterSelector, classFilterSelector],
  (locationFilter, teacherFilter, classFilter) => ({
    location: locationFilter,
    teacher: teacherFilter,
    class: classFilter,
  })
)

const filteredResultsSelector = state => state.filteredResults
export const selectFilteredResults = createSelector(
  [filteredResultsSelector],
  filteredResults => filteredResults
)

const showScheduleSelector = state => state.showSchedule
export const selectShowSchedule = createSelector(
  [showScheduleSelector],
  showSchedule => showSchedule
)
