import axios from "axios"
import Types from "./types"
import {
  selectLocationSelector,
  selectCurrentFilter,
  selectAllClasses,
} from "./selectors"
import { getLastSunday } from "../utils/utils"

import descriptions from "../data/classesDescription"
import { colors, categories } from "../data/classesColors"
import moment from "moment"

const FitogramTrainersURL = "https://fps.fitogram.pro/trainers/green-yoga"
const FitogramLocationsURL =
  "https://fps.fitogram.pro/locations/green-yoga?archived=false"
const FitogramClassesURL = "https://event-service.fitogram.pro/events?"

export const FetchFitogramDataStart = () => async (dispatch, getState) => {
  try {
    let currentState = getState()
    let currentClasses = selectAllClasses(currentState)

    if (currentClasses.length === 0) {
      dispatch({ type: Types.FETCH_FITOGRAM_DATA_START })
      let FetchTrainers = await axios.get(FitogramTrainersURL)
      let FetchLocations = await axios.get(FitogramLocationsURL)
      let FetchClasses = await axios.get(FitogramClassesURL, {
        params: {
          appointments: "true",
          classes: "true",
          courses: "true",
          from: getLastSunday(),
          locationUUIDs: "",
          page: "0",
          preventions: "true",
          providerId: "b71b73c9-9e2f-44cb-91b1-9a7768b63031",
          size: "900",
        },
      })

      let Trainers = FetchTrainers.data

      let Locations = FetchLocations.data
      let Classes = FetchClasses.data

      dispatch({
        type: Types.FETCH_FITOGRAM_DATA_SUCCESS,
        payload: { Trainers, Locations },
      })
      dispatch(createScheduleData(Trainers, Classes, Locations))
    } else {
      dispatch(filterSchedule())
    }
  } catch (error) {
    dispatch({
      type: Types.FETCH_FITOGRAM_DATA_FAILURE,
      payload: error.message,
    })
  }
}

const createScheduleData = (Trainers, Classes, Locations) => dispatch => {
  dispatch({ type: Types.GENERATING_DATA_START })

  let AllClasses = []

  let yogaStyles = ["All Styles"]
  let undefinedStyles = []

  try {
    Classes.forEach(item => {
      if (!item.cancelled) {
        let current = {
          Id: item.id,
          Subject: item.name
            .replace(/\b(?:Online|ONLINE|IN STUDIO|Xberg|Pberg)\b/i, "")
            .replace(/\b\s*(?:Xberg|Pberg)\b/i, "")

            .trimStart()
            .trimEnd()
            .replace(/\b(?:Hatha Flow Yoga)\b/i, "Hatha Flow")
            .replace(
              /\b(?:Vibrant Vinyasa Flow|Vinyasa Flow Yoga)\b/i,
              "Vinyasa Flow"
            )
            .replace(/\b(?:Wake-Up Yoga)\b/i, "Wake Up Yoga")
            .replace(/(?:Gong Bath \(Gong Meditation\))/i, "Gong Bath")
            .replace(
              /(?:Yin Yoga And Sound Healing)/i,
              "Yin Yoga & Sound Healing"
            )
            .replace(
              /(?:Yoga \+ Meditation|Yoga\+Meditation|Yoga and Meditation)/i,
              "Yoga & Meditation"
            )
            .replace(/(?:Kundalini Yoga)/i, "Kundalini")
            .replace(/(?:Power Yoga|Power Vinyasa Yoga)/i, "Power Vinyasa")
            .replace(/(?:Iyengar Yoga \(Copy Product\))/i, "Iyengar Yoga")
            .replace(/(^|\s)([a-z])/g, function (m, p1, p2) {
              return p1 + p2.toUpperCase()
            }),
          StartTime: moment(item.start).local().toDate(),
          EndTime: moment(item.end).local().toDate(),
          LiveStream: item.livestream || "",
          VirtualClassUrl: item.virtualClassUrl || "",
          Location: Locations.find(i => i.uuid === item.locationId)
            ? Locations.find(i => i.uuid === item.locationId)?.name
            : "",
          Teacher: Trainers.find(i => i.trainerId === item.trainerIds[0])
            ? Trainers.find(i => i.trainerId === item.trainerIds[0])?.firstName
            : "",
        }

        if (!descriptions[current.Subject])
          undefinedStyles.push(current.Subject)
        AllClasses.push({
          ...current,
          Description: descriptions[current.Subject],
          Category: categories[colors[current.Subject]],
        })
        console.log(AllClasses)
        yogaStyles.push(current.Subject)
      }
    })
    //   console.log([...new Set(undefinedStyles)], "undefined")
    yogaStyles = [...new Set(yogaStyles)]
    dispatch({
      type: Types.GENERATING_DATA_SUCCESS,
      payload: AllClasses,
    })
    /*   dispatch({
      type: Types.SET_YOGA_STYLES,
      payload: yogaStyles.sort(),
    }) */
    /*  dispatch(filterSchedule()) */
  } catch (error) {
    console.log(error)
    dispatch({ type: Types.GENERATING_DATA_FAILURE, payload: error.message })
  }
}

export const filterSchedule = () => (dispatch, getState) => {
  try {
    dispatch({ type: Types.FILTER_CLASSES_START })
    let currentState = getState()
    let currentFilter = selectCurrentFilter(currentState)

    let filteredData
    if (
      currentFilter.teacher.length > 0 &&
      currentFilter.teacher !== "All Teachers"
    ) {
      filteredData = selectAllClasses(currentState)
      filteredData = filteredData.filter(item => {
        return (
          item.Teacher !== "" && currentFilter.teacher.includes(item.Teacher)
        )
      })
      filteredData =
        currentFilter.class.length > 0 && currentFilter.class !== "All Styles"
          ? filteredData.filter(item =>
              item.Subject.includes(currentFilter.class)
            )
          : filteredData
    } else {
      //always filter by location ther rest is optional
      //by teachers
      filteredData = selectLocationSelector(currentFilter.location)(
        currentState
      )
      filteredData =
        currentFilter.class.length > 0 && currentFilter.class !== "All Styles"
          ? filteredData.filter(item =>
              item.Subject.includes(currentFilter.class)
            )
          : filteredData
    }
    dispatch({
      type: Types.FILTER_CLASSES_SUCCESS,
      payload: filteredData,
    })

    //if there are any other filters apply them to the filteredBylocation results
  } catch (error) {
    console.log(error)
    dispatch({ type: Types.FILTER_CLASSES_ERROR, payload: error.message })
  }
}

export const setFilter = filter => dispatch => {
  switch (filter.type) {
    case "location":
      dispatch({ type: Types.SET_LOCATION_FILTER, payload: filter.value })
      break
    case "class":
      dispatch({
        type: Types.SET_CLASS_FILTER,
        payload: filter.value !== "All Styles" ? filter.value : "",
      })
      break
    case "teacher":
      dispatch({ type: Types.SET_TEACHERS_FILTER, payload: filter.value })
      break
    default:
  }
  dispatch(filterSchedule())
}

export const resetFilter = () => dispatch =>
  dispatch({ type: Types.RESET_FILTER })

export const setShowSchedule = showSchedule => dispatch => {
  dispatch({ type: Types.SET_SHOW_SCHEDULE, payload: showSchedule })
}

export const setScheduleLocation = location => dispatch =>
  dispatch({ type: Types.SET_SCHEDULE_LOCATION, payload: location })
