const descriptions = {
  "Ashtanga Vinyasa":
    "Challenging Ashtanga series praticed in a flow synchronising with the breath  to produce heat and adrenaline.",
  "Chakra Yoga (Deutsch)":
    "Using yoga postures, breathing and mantras to rebalance your 7 energy chakras. ",
  "Express Core Strength":
    "Core work elements integrated into a flow to strengthn the entire centre of the body including the lower/middle back.",
  "Dream Wave":
    "Dream wave is a Yoga Nidra perfect to relax and release any tension in body and mind before you dive into your dreams. ",
  "Express Dynamic Mindfulness":
    "Mindful movements to improve strength and flexibility as well as your understanding of your body and mind.",
  "Express Ecstatic Yoga":
    "Inspired by ecstatic dance, freely moving your body to feel awake and alive as well as feeling at home in your body. ",
  "Express Vinyasa":
    "This morning flow will help you start the morning by focusing on some beneficial stretches for your body and mind. ",
  "Gentle Flow":
    "Very soft and gentle vinyasa flow giving you the space to consiously stretch, move and breathe.",
  "Gong Bath":
    "The sound of the gong allows complete relaxation as well as balances your emotions and bio-rhythm.",
  "Express Happy Spine":
    "Move your back in all six directions, undulate, twist and bend to make your whole body feel delicious!",
  "Hatha Flow":
    "An attentive and detailed flow of guided postures aligned with the rhythm of the breath.",
  "Hatha Flow (Deutsch)":
    "An attentive and detailed flow of guided postures aligned with the rhythm of the breath.",
  "Hatha Tantra Yoga":
    "Asanas combined with breathing techniques, meditation, mantras and mudras to awaken your sensuality and vitality.",
  "Hatha Yoga":
    "The foundational yoga to create balance in the mind and body and work on your muscular structure and nerve system.",
  "Hatha Yoga (Deutsch)":
    "The foundational yoga to create balance in the mind and body and work on your muscular structure and nerve system.",
  "Iyengar Yoga":
    "Alignment is the key to bring a deeper understanding of each asana and your body.",
  Kundalini:
    "Fixed sequence of mantras, meditation, and yoga postures after Yogi Bhajan aiming to awaken the kundalini energy in you.",
  "Kundalini (Deutsch)":
    "Fixed sequence of mantras, meditation, and yoga postures after Yogi Bhajan aiming to awaken the kundalini energy in you.",
  "Express Kundalini Workout":
    "Designed to make you sweat, sculpt your body and bring you into the higher realms of awareness.",
  "Express Lower Back Pain Yoga":
    "Mind-body therapy not only to treat back pain, but to help you gain more awareness of your body as a whole.",
  "Express Lucid Breathing":
    "Journey of ancient and modern breathing techniques in order to remove physical as well as energetic blockages.",
  "Lucid Garden":
    "Guiding your way home. Deep relaxation with storytelling to end the day.",
  "Express Mantra, Pranayama & Meditation":
    "A combination of breath, sound and stillness to activate the heart, throat and third eye chakras.",
  Meditation:
    "Oberserving thoughts, emotions and body sensations as they are. Practicing acceptance of what is. ",
  "Mindful Stretching":
    "Combination of slow stretches, gentle movement, and breath with the inner perception of body and mind.",
  "Express Morning Movement Meditation":
    '"Meditation in movement" consisting of a series of postures to deepen the connection with your body.',
  "Morning Vinyasa":
    "Wake up your body with a dynamic yet mindful asana sequence connecting to your body. ",
  "Express Morning Vinyasa":
    "Wake up your body with a dynamic yet mindful asana sequence connecting to your body. ",
  Pilates:
    "Mat-based workout to help develop a rock-solid core while leaving no muscle untouched. ",
  "Pilates & Dance":
    "Combining rigid core excercises with intuitive dance movements.",
  "Power Vinyasa":
    "A fast-paced practice of yoga focusing on building strength and flexibility through cardio and mental stamina. ",
  "Prenatal Yoga":
    "Movement, breath, singing and meditation to connect Mama to herself, her baby, and the community.",
  "Express Rebalance Yoga":
    "Come back into your body, ground yourself, shake off the toxics to rebalance body and mind. ",
  "Express Slow Flow":
    '"Meditation in action" as a gentle, slow yoga flow focusing on exact alignment to increase body awareness. ',
  "Express Speed Pilates":
    "Starting with a gentle warm-up, gradually increasing the intensity to then move into core exercises on the mat. ",
  "Theta Dreams":
    "Using Thetahealing meditation to find that place of deep peace within you and manifest your dreams. ",
  "Vinyasa Flow":
    "Marriage of breath and the dynamic movement through different asanas (poses), focusing on balance, strength and flexibility.",

  "Wake Up Breath-Work And Yoga":
    "Mixing asanas and breathwork to get you started into the day.",
  "Wake Up Yoga":
    "Dynamic wake-up flow leaving you feeling energised, awake and ready to start your day. ",
  "Express Yin Flow":
    "Slow paced class with a gentle warm-up, followed by some deep stretches targeting the deep fascia connective tissues.",
  "Yin Yang Yoga":
    "Integration of joints and muscles, making the practice more motion-based centering and restoring us with new energy.",
  "Yin Yang":
    "Integration of joints and muscles, making the practice more motion-based centering and restoring us with new energy.",
  "Yin Yang Yoga (Deutsch)":
    "Integration of joints and muscles, making the practice more motion-based centering and restoring us with new energy.",
  "Yin Yoga":
    "Deeply relaxing slow-paced strechting class suitable for beginners, sore and inflexible bodies, as well as runners and athletes.",
  "Yin Yoga (Deutsch)":
    "Deeply relaxing slow-paced strechting class suitable for beginners, sore and inflexible bodies, as well as runners and athletes.",
  "Yin Yoga & Sound Healing":
    "Slow-paced strechting class adding the power of sound to deeply relax body and mind. ",
  "Yoga & Meditation":
    "Meditation before and after your yoga practice will increase your attention on the present moment.",
  "Express Yoga And Hip Pop":
    "Combining traditional elements of Yoga with urban vibes like HipHop, Rap, RnB and Jazz music.",
  "Express Yoga And Jazz":
    "A gentle, slow flow of yoga asanas accompanied with some deeply soulful and mystical tracks.",
  "Express Yoga Beats Berlin":
    "Merging Yoga and electronic music guiding you to the land of homemade minimal and more technoid beats.",
  "Express Yoga For Mental Health":
    "Grounding poses, forward bends and breathing exercises to help calming the nervous system for mental health. ",
  "Express Yoga For Women":
    "Learn as a woman how to align your yoga practice with your monthly menstruation cycle. ",
  "Yoga Meets Dance":
    "Alliance between the intuition of dance improvisation and the clarity of the yoga asanas.",
  "Yoga Nidra":
    "Reach a sleep-like state by triggering awareness on the body, breath, emotional states, visualisation and self-healing.",
  "Express Yoga Nidra":
    "Reach a sleep-like state by triggering awareness on the body, breath, emotional states, visualisation and self-healing.",
  "Quick Wake Up Yoga":
    "Get your blood flowing and alleviate tiredness to feel strong and steady with a wake-up flow consisting of pranayama and yogasanas. The dynamic classes will leave you feeling energised, awake and ready to start your day. Open to all levels.",
  "Vibrant Vinyasa Flow":
    "Wake up through a vibrating Vinyasa flow. Combining asana practice with the breath to energies your body, get your heart vibrating and your mind focused to start you day with a positive intention.",
  "Chakra Yoga":
    "Release, awaken and energize your chakras. Chakra yoga brings attention to the chakras and helps release physical and emotional blocks. Each class focuses on one chakra individually. You can expect a loving vinyasa flow and insights into the chakras. ",

  "Green Yoga Flow":
    "We at Green Yoga would like to walk you through devotion, asanas and meditation. Come with an open mind to surrender the thinker to the higher intelligence. There is more to our life than what the thinking mind perceives, this class is an invitation to access that which is not. We will laugh, sing, go through asanas and experience oneness with the higher intelligence",
  "Rise and Shine Vinyasa":
    "Live the experience of waking up to yoga as you lift your spirit, awake your mind and stretch your body. Boots your energy, salutating the sun with a smile as you let go all the tightness from your sleep. You will shine! And best of all is that your will keep it throughout your day.",
  "Dance Meets Yoga":
    "Learn to move with the beat of your body when immersing yourself into an improvisation of intuitive and ecstatic sequencing. Classes combine the aspects of movement awareness with yogasana practice, creating an alliance between the intuition of the improvisation and the clarity of the asanas. No previous experience in dance required, just openness to move and explore.",
  "Mantra Meditation":
    "We will recite mantras to balance our chakras. Come with an open mind and experience the bliss of transcending the mind. Traditionally this form of yoga is called Nada Yoga.",
  "Bhakti Yoga & Sound Healing":
    "Bhakti yoga, like any other form of yoga, is a path to self-realization, to having an experience of oneness with everything through devotion. Devotion to the higher intelligence that is bigger than our egoic intelligence through surrender is what we will learn. In this class we will connect movement with the power of music. This class inspires a deepening into a calm and centered state of being. Come to listen, come to be heard, come to tap into your nature.",
  "Techno Meets Yoga":
    "Alliance between the intuition of electronic music and the clarity of yoga asanas, immersing ourselves into an ecstatic state through the improvisation of intuitive and ecstatic sequencing. Learn to move with the beat of your body. ",
  "Morning Breathing":
    "Prana refers to life force and ayama to control- Pranayama is considered the control of the breath. In this class we systematically go through the different techniques to prepare the body to work with our vital energy.",
  "Anapana Meditation":
    "In Anapana Meditation we are using the breath as object of meditation/concentration. Coming fully into the here and now continously following the natural flow of your breath.",
  "Express Neck And Shoulder":
    "Release the tension of the week from your neck and shoulders with applied Mindful Stretching. The class combines slow passive stretches, fascia stretches, breath and gentle movement. Expect a relaxing yet mindful class, with possible pinches of movement, shaking, and self-massage as additional tools to liberate tension.",
  "Gentle Vinyasa":
    "Fluid conscious movement on the speed of breath being the main focus blending mind, body and breath.",
  "Fitness through Groove":
    "We will warm up, we will explore different qualities in our bodies, we will strengthen, bring mobility, flexibility, resourcefulness, awareness, sweat, rhythm, swing, we will pose, we will groove together.",
  "Movement exploration (Dance)":
    "In this class we work with body scans, awareness through movement, and somatic-dance explorations. Cultivating a caring curiosity, we start from soft and subtle movements on the floor to open up our kinesthetic sense, towards an exploration in space. We learn to truly feel where we are and how we are, gently relaxing the judging voice that blocks our potential. Can I allow myself to be exactly the way I am right now ? What happens when I do so ?		NB: most of the class is on the floor, no jump and no big space necessary. Prepare a mat and a blanket. Suitable for all levels.",

  "Meditation Cycle - Sitting Walking Dancing":
    "This meditation cycle is a simple but powerful practice of pausing, breathing and being in each present moment. Just sit, just walk and just dance. A practice to reassess each moment and cultivate the inner peace.",

  "Slow Flow":
    "In the first part, a soft and gentle vinyasa flow finds space for deep breathing to take place, in the second part a mindful stretching. A mix in between a vinyasa and a yin practice, less rigid form for both body and mind giving you space to stretch, move and breathe. Best for beginners.",

  "Energising Vinyasa Yoga":
    "Vinyasa flow combines the breath with movement for a dynamic sequence to energise and awaken the mind and body. This practice emphasise breath-work married with physical asanas (poses), focusing on balance, movement, flexibility and alignment. Use of energising music to stimulate the senses. Open to all levels, it can be challenging for beginner.",
  "Rise And Shine Vinyasa":
    "	Live the experience of waking up to yoga as you lift your spirit, awake your mind and stretch your body. Boots your energy, salutating the sun with a smile as you let go all the tightness from your sleep. You will shine! And best of all is that your will keep it throughout your day.",
  "Rise And Shine Vinyasa Flow":
    "	Live the experience of waking up to yoga as you lift your spirit, awake your mind and stretch your body. Boots your energy, salutating the sun with a smile as you let go all the tightness from your sleep. You will shine! And best of all is that your will keep it throughout your day.",
  "Fitness Through Groove":
    "	We will warm up, we will explore different qualities in our bodies, we will strengthen, bring mobility, flexibility, resourcefulness, awareness, sweat, rhythm, swing, we will pose, we will groove together.",
  "Groove It Up":
    "	We will warm up, we will explore different qualities in our bodies, we will strengthen, bring mobility, flexibility, resourcefulness, awareness, sweat, rhythm, swing, we will pose, we will groove together.",
  "Movement Exploration: Where Is My Body?":
    "In this class we work with body scans, awareness through movement, and somatic-dance explorations. Cultivating a caring curiosity, we start from soft and subtle movements on the floor to open up our kinesthetic sense, towards an exploration in space. We learn to truly feel where we are and how we are, gently relaxing the judging voice that blocks our potential. Can I allow myself to be exactly the way I am right now ? What happens when I do so ?",
  "Sunday Evening Flow":
    "	In the first part, a soft and gentle vinyasa flow finds space for deep breathing to take place, in the second part a mindful stretching. A mix in between a vinyasa and a yin practice, less rigid form for both body and mind giving you space to stretch, move and breathe. Best for beginners.",
  "Express Flow Yoga":
    "In the first part, a soft and gentle vinyasa flow finds space for deep breathing to take place, in the second part a mindful stretching. A mix in between a vinyasa and a yin practice, less rigid form for both body and mind giving you space to stretch, move and breathe. Best for beginners.",
  "Quick Vinyasa":
    "Vinyasa flow combines the breath with movement for a dynamic sequence to energise and awaken the mind and body. This practice emphasise breath-work married with physical asanas (poses), focusing on balance, movement, flexibility and alignment. Use of energising music to stimulate the senses. Open to all levels, it can be challenging for beginner.",
  "Vinyasa Flow (Deutsch)":
    "Vinyasa ist eine dynamische Sequenz in der wir den Atem mit der Bewegung und damit den Geist und Körper in Einklang bringen. Diese wirkt stimulierend und aktivierend auf den Körper. Jede Stunde werden wir einen speziellen Fokus legen. Offen für alle Niveaus, kann es für Anfänger eine Herausforderung sein.",

  "OUTDOOR Vinyasa Flow Under The Trees":
    "Vinyasa flow combines the breath with movement for a dynamic sequence to energise and awaken the mind and body. This practice emphasise breath-work married with physical asanas (poses), focusing on balance, movement, flexibility and alignment. Use of energising music to stimulate the senses. Open to all levels, it can be challenging for beginner.",
  "OUTDOOR POWER VINYASA (GER)":
    "Vinyasa ist eine dynamische Sequenz in der wir den Atem mit der Bewegung und damit den Geist und Körper in Einklang bringen. Diese wirkt stimulierend und aktivierend auf den Körper. Jede Stunde werden wir einen speziellen Fokus legen. Offen für alle Niveaus, kann es für Anfänger eine Herausforderung sein.",
  "Vinyasa Flow under the trees":
    "Vinyasa flow combines the breath with movement for a dynamic sequence to energise and awaken the mind and body. This practice emphasise breath-work married with physical asanas (poses), focusing on balance, movement, flexibility and alignment. Use of energising music to stimulate the senses. Open to all levels, it can be challenging for beginner.",

  "Morning Mobility Flow":
    "Give your body and mind a good start to the day, you will be guided through a series of functional movements and a short flow that will awaken your body and mind, setting you up with a good posture alignment for the rest of your day.  ",
  "Core Yoga":
    "Core Yoga is a grounding practise that creates stability and strength by activating your deep abdominal muscles. A strong core awareness is not only beneficial for any type of Asana but particularly helps to relieve pressure on your spine as well as connecting you to your deep abdominal breath - a tool used to deal with stress and anxiety.",
  "Chakra Vinyasa Flow":
    "Each class has a specific yoga asanas and breathing exercises to activate desired qualities that are associated with each of the body’s energy centers, experiencing your own divine essence. ",
  "Gong MEM Yoga":
    "In a blend of Indian yogic practices and Chinese chi flow oneness meets full presence. Through intuition, breath, intention, movement and awareness we gently release stuck congested energy, release stress and bring emotions into dynamic balance promoting health, wellbeing and inner peace. The practice is suited for all levels.",
  "VOLKSPARK HATHA FLOW":
    "You will be guided through traditional poses in a well thought flow. ",
  "VOLKSPARK GENTLE FLOW":
    "Gently you will get guided through a series of asanas to strengthens the mental health, to be connected with yourself and ",
  "VOLKSPARK VINYASA FLOW":
    "We start with a warm up, combine breath and movement, exagerate into the movements, strengthens the core. Every practice has an activating and a mindful part to connect with yourself, relieve on stress and fulfill your body integrated with your mind.   ",
  "VOLKSPARK ADVANCED VINYASA FLOW":
    "Advanced Vinyasa Flow addresses people who are familiar with the basic asanas, breathing experience and dynamic sequencing. ",
  "VOLKSPARK FASCIAL STRETCHING":
    "Fascia is the connective tissue that hold all together in our body. During Fascia stretching we shorten and activate parts of the body to clear dense tissue, feel reenergised and uplifted, increase flexibility and muscle performance while decreasing the risk of injury.",
  "VOLKSPARK MORNING FLOW":
    "Get awake with Vinyasa Flow, a practice to combine breath and movements in a dynamic and mindful sequencing. We focus on flexibility, strenght, movements, balances to become fresh for the day.",
  "VOLKSPARK MANTRA SINGING":
    "Dvora Gala Blu - Singer, Songwriter, Yogateacher - leads this Mantra Singing Class in a soulful powerful and ceremonial way. Chanting raises vibration, calms the mind and opens the heart bringing us in the presnet moment.",
  "VOLKSPARK POWER LUNCH":
    "This class combines traditional asanas and concious breathing during creative sequencing fulfilled with a regenerative savasana. It will recharge your batteries, feel your body, calm your thoughts, connect to the inner world and wishes as well as give you a quality break on your day.",
  "HASENHEIDE Yoga for Creativity heide":
    "This practice focuses on the power of creative energy. Through mindful movement, breathing techniques, mudras and visualizations we dive deep into the sacral and throat chakras, making space for healing, improvisation and exploration. It’s a playful and relaxing class, yet powerful for experiencing a stronger connection with our own free flow of creativity. Open to all levels.",
  "Groove It Up":
    "Missing to bounce on the dance floor? Looking for a fun alternative to gym workouts? Would love to tap into some pills of joy in your body? This class is divided in 3 parts: floor work (warm up/planks); dancing to rhythmic music with simple instructions or steps to play with; and stretching. Find a floor space in your house, plug your device in a speaker, open your videos if possible and let's groove it up.",
  "HASENHEIDE Creativity Yoga heide":
    "This practice focuses on the power of creative energy. Through mindful movement, breathing techniques, mudras and visualizations we dive deep into the sacral and throat chakras, making space for healing, improvisation and exploration. It’s a playful and relaxing class, yet powerful for experiencing a stronger connection with our own free flow of creativity. Open to all levels.",
  "Astrology & Alignment":
    "A Hatha based class pairing asana, pranayama, sound, and stillness with current astral happenings. Suitable for all levels.",
  "Pilates for Flexibility":
    "Pilates for flexibility focuses on dynamic and static exercises that will improve your flexibility and core strength.",
  "Ashtanga Yoga":
    "Start your week with a modified Ashtanga primary series, to build strength, discipline and balance for the week ahead of you. The practice is designed any level of Yogi and Yogini, with variations and props use for anybody.",
  "Ashtanga Weekstarter":
    "Start your week with a modified Ashtanga primary series, to build strength, discipline and balance for the week ahead of you. The practice is designed any level of Yogi and Yogini, with variations and props use for anybod",
  "Meditation (Deutsch)":
    "Perfekte Kombination aus geführter Meditationen, Atem- und Körperentspannungsübungen und einer Fantasiereise.",
  "Flying Vinyasa":
    "Super playful practice aimed at Yogi and Yoginis who like to challenge and develop their balance skills. This practice will lead you to arm balances, floating asanas and upside-down inversions. Designed for all levels and full of modifications and props use to ease it up for less experienced practitioners.",
}
export default descriptions
