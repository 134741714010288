/* '#8ad087' */
export const colors = {
  "Ashtanga Vinyasa": 1,
  "Chakra Yoga (Deutsch)": 2,
  "Express Core Strength": 1,
  "Dream Wave": 0,
  "Express Dynamic Mindfulness": 2,
  "Express Ecstatic Yoga": 2,
  "Express Vinyasa": 1,
  "Gentle Flow": 2,
  "Gong Bath": 0,
  "Express Happy Spine": 2,
  "Hatha Flow": 1,
  "Hatha Flow (Deutsch)": 1,
  "Hatha Tantra Yoga": 1,
  "Hatha Yoga": 1,
  "Hatha Yoga (Deutsch)": 1,
  "Iyengar Yoga": 1,
  Kundalini: 2,
  "Kundalini (Deutsch)": 2,
  "Express Kundalini Workout": 1,
  "Express Lower Back Pain Yoga": 2,
  "Express Lucid Breathing": 0,
  "Lucid Garden": 0,
  "Express Mantra, Pranayama & Meditation": 0,
  Meditation: 0,
  "Mindful Stretching": 0,
  "Express Morning Movement Meditation": 2,
  "Morning Vinyasa": 1,
  "Express Morning Vinyasa": 1,
  Pilates: 1,
  "Pilates & Dance": 1,
  "Power Vinyasa": 1,
  "Prenatal Yoga": 2,
  "Express Rebalance Yoga": 2,
  "Express Slow Flow": 2,
  "Express Speed Pilates": 1,
  "Theta Dreams": 0,
  "Vinyasa Flow": 1,
  "Vinyasa Flow (Deutsch)": 1,
  "Wake Up Breath-Work And Yoga": 1,
  "Wake Up Yoga": 1,
  "Express Yin Flow": 2,
  "Yin Yang Yoga": 2,
  "Yin Yang": 2,
  "Yin Yang Yoga (Deutsch)": 2,
  "Yin Yoga": 0,
  "Yin Yoga & Sound Healing": 0,
  "Yoga & Meditation": 2,
  "Express Yoga And Hip Pop": 1,
  "Express Yoga And Jazz": 1,
  "Express Yoga Beats Berlin": 1,
  "Express Yoga For Mental Health": 2,
  "Express Yoga For Women": 2,
  "Yoga Meets Dance": 1,
  "Yoga Nidra": 0,
  "Express Yoga Nidra": 0,
  "Quick Wake Up Yoga": 1,
  "Vibrant Vinyasa Flow": 1,
  "Chakra Yoga": 2,
  "Green Yoga Flow": 2,
  "Rise and Shine Vinyasa": 2,
  "Dance Meets Yoga": 2,
  "Mantra Meditation": 0,
  "Bhakti Yoga & Sound Healing": 0,
  "Techno Meets Yoga": 1,
  "Morning Breathing": 2,
  "Anapana Meditation": 0,
  "Express Neck and Shoulder": 0,
  "Gentle Vinyasa": 2,
  "Fitness through Groove": 1,
  "Movement exploration (Dance)": 0,
  "Meditation Cycle - Sitting Walking Dancing": 0,
  "Slow Flow": 2,
  "Energising Vinyasa Yoga": 1,
  "Rise And Shine Vinyasa": 1,
  "Rise And Shine Vinyasa Flow": 1,
  "Fitness Through Groove": 1,
  "Groove It Up": 1,
  "Movement Exploration: Where Is My Body?": 0,
  "Sunday Evening Flow": 2,
  "Express Flow Yoga": 1,
  "Quick Vinyasa": 1,

  "OUTDOOR Vinyasa Flow Under The Trees": 1,
  "OUTDOOR POWER VINYASA (GER)": 1,
  "Vinyasa Flow under the trees": 1,

  "Morning Mobility Flow": 1,
  "Core Yoga": 1,
  "Chakra Vinyasa Flow": 2,
  "Gong MEM Yoga": 2,
  "VOLKSPARK HATHA FLOW": 1,
  "VOLKSPARK GENTLE FLOW": 2,
  "VOLKSPARK VINYASA FLOW": 1,
  "VOLKSPARK ADVANCED VINYASA FLOW": 1,
  "VOLKSPARK FASCIAL STRETCHING": 1,
  "VOLKSPARK MORNING FLOW": 2,
  "VOLKSPARK MANTRA SINGING": 0,
  "VOLKSPARK POWER LUNCH": 2,
  "HASENHEIDE Yoga for Creativity heide": 2,
  "Groove It Up": 1,
  "HASENHEIDE Creativity Yoga heide": 2,
  "Astrology & Alignment": 2,
  "Pilates for Flexibility": 2,
  "Ashtanga Yoga": 1,
  "Ashtanga Weekstarter": 1,
  "Meditation (Deutsch)": 0,
  "Flying Vinyasa": 1,
}
export const categories = {
  0: { category: "Yin", color: "#7A9D9D" },
  1: { category: "Yang", color: "#2C402B" /* '#C37469' */ },
  2: { category: "YinYang", color: "#b6d7a8" /* '#69C38E' */ },
  3: { category: "Express", color: "#b6d7a8" /* ' #219aa5'  */ },
}
